import React from 'react';

const WattScannerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="234.004px"
    height="37.806px"
    viewBox="0 -12.335 234.004 37.806"
    enableBackground="new 0 -12.335 234.004 37.806"
    xmlSpace="preserve"
  >
    <g enableBackground="new">
      <path
        fill="#637170"
        d="M27.688-3.964l-5.344,19.335h-1.987L15.255-2.514l-5.156,17.885H8.058L2.714-3.964h1.558l4.86,17.805
		l5.129-17.805h2.068l5.021,17.751l4.887-17.751H27.688z"
      />
      <path
        fill="#637170"
        d="M43.103,15.371l-2.175-5.479h-9.775l-2.175,5.479h-1.584l7.76-19.335h1.88l7.734,19.335H43.103z
		 M31.664,8.63h8.781l-4.35-11.145L31.664,8.63z"
      />
      <path
        fill="#637170"
        d="M58.464-2.622h-6.525v17.992h-1.504V-2.622h-6.499v-1.343h14.527V-2.622z"
      />
      <path
        fill="#637170"
        d="M73.905-2.622H67.38v17.992h-1.504V-2.622h-6.498v-1.343h14.527V-2.622z"
      />
    </g>
    <g enableBackground="new    ">
      <path
        fill="#637170"
        d="M110.103,5.704c-4.377-0.968-5.961-2.604-5.961-5.317c0-2.766,2.31-4.753,6.659-4.753
		c3.813,0,6.688,1.799,7.278,4.833h-1.559c-0.483-2.202-2.577-3.544-5.692-3.544c-3.518,0-5.156,1.423-5.156,3.384
		c0,2.067,1.021,3.195,5.479,4.189c5.155,1.182,6.929,2.874,6.929,5.88c0,3.008-2.552,5.398-7.225,5.398
		c-4.001,0-6.928-1.934-7.546-5.344h1.585c0.51,2.658,2.847,4.028,5.961,4.028c3.679,0,5.692-1.639,5.692-4.028
		C116.547,8.066,115.339,6.858,110.103,5.704z"
      />
      <path
        fill="#637170"
        d="M135.91,8.953h1.504c-0.402,3.76-3.384,6.821-7.895,6.821c-5.209,0-8.727-3.841-8.727-9.909
		c0-6.633,3.784-10.231,8.727-10.231c4.188,0,7.357,2.363,7.921,6.229h-1.503c-0.618-3.222-3.062-4.914-6.418-4.914
		c-4.082,0-7.17,3.115-7.17,8.916c0,5.37,3.061,8.594,7.196,8.594C133.01,14.458,135.507,12.364,135.91,8.953z"
      />
      <path
        fill="#637170"
        d="M154.602,15.371l-2.175-5.479h-9.774l-2.176,5.479h-1.584l7.761-19.335h1.879l7.733,19.335H154.602z
		 M143.162,8.63h8.78l-4.35-11.145L143.162,8.63z"
      />
      <path
        fill="#637170"
        d="M173.373-3.964v19.335h-1.772L160.08-2.031v17.401h-1.423V-3.964h1.853l11.493,17.294V-3.964H173.373z"
      />
      <path
        fill="#637170"
        d="M192.493-3.964v19.335h-1.772l-11.52-17.401v17.401h-1.424V-3.964h1.854l11.493,17.294V-3.964H192.493z"
      />
      <path
        fill="#637170"
        d="M207.559,6.187h-9.157v7.868h9.936v1.315h-11.439V-3.964h11.439v1.317h-9.936v7.545h9.157V6.187z"
      />
      <path
        fill="#637170"
        d="M224.478,15.371l-5.183-7.949h-5.72v7.949h-1.504V-3.964h6.605c4.35,0,6.768,2.149,6.768,5.801
		c0,2.792-1.988,4.645-4.593,5.343l5.451,8.19H224.478z M213.575-2.675v8.808h5.102c3.249,0,5.183-1.773,5.183-4.296
		c0-2.874-1.746-4.512-5.129-4.512H213.575z"
      />
    </g>
    <g id="XMLID_1479_">
      <path
        id="XMLID_1481_"
        fill="#6CC286"
        d="M140.742,276.468l4.916-8.746l-4.539-1.247l9.076-9.801
		c-1.181-0.508-2.479-0.791-3.847-0.791c-5.378,0-9.737,4.36-9.737,9.737c0,3.479,1.824,6.529,4.568,8.251L140.742,276.468
		L140.742,276.468z"
      />
      <path
        id="XMLID_1480_"
        fill="#A4CE4A"
        d="M151.513,257.366l0.442-2.592l0,0l-4.877,8.707l4.49,1.287l-9.066,9.8
		c1.181,0.509,2.48,0.791,3.849,0.791c5.378,0,9.736-4.359,9.736-9.736C156.086,262.141,154.259,259.086,151.513,257.366z"
      />
    </g>
    <g id="XMLID_552_">
      <polygon
        id="XMLID_758_"
        fill="#3FB3B3"
        stroke="#FFFFFF"
        strokeWidth="0.073"
        strokeMiterlimit="10"
        points="97.197,-11.334 
		77.057,7.627 101.905,0.898 91.355,0.714 	"
      />
      <polygon
        id="XMLID_787_"
        fill="#B7C735"
        stroke="#FFFFFF"
        strokeWidth="0.073"
        strokeMiterlimit="10"
        points="75.585,23.469 
		95.725,4.508 70.877,11.234 81.425,11.419 	"
      />
    </g>
  </svg>
);
export default WattScannerIcon;
