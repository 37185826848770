import React from 'react';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../svg/LogoIcon';
import WattScannerIcon from '../../svg/WattScannerIcon';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-wrap sm:flex-row justify-between items-center mx-auto py-4 px-8">

      <div className="flex flex-wrap items-center text-2xl">
        <div className="w-auto ml-3 items-center">
          <WattScannerIcon />
        </div>
      </div>

      <div className="flex flex-wrap mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#analytics">
          Analytics
        </AnchorLink>
        <AnchorLink className="px-4" href="#services">
          Services
        </AnchorLink>
        <AnchorLink className="px-4" href="#pricing">
          Pricing
        </AnchorLink>
        <AnchorLink className="px-4" href="#testimonials">
          Testimonials
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <a href={`${process.env.GATSBY_CUST_APP_URI}`}>
          <Button className="text-sm">Coming Soon!</Button>
        </a>
      </div>
    </div>
  </header>
);

export default Header;
